import React, { PropsWithChildren, ReactNode } from "react"
import "./Leader.css"

interface LeaderProps {
  leaderTitleStrings?: string[]
  leaderContentNodes?: ReactNode[]
}

function Leader({
  leaderTitleStrings,
  leaderContentNodes,
}: PropsWithChildren<LeaderProps>): ReturnType<React.FC> {
  return (
    <div className="leader">
      <div className="leader-title">
        {leaderTitleStrings?.map((titlePart) => (
          <span>{titlePart}</span>
        ))}
      </div>
      <div className="leader-content">
        {leaderContentNodes?.map((node) => (
          <span>{node}</span>
        ))}
      </div>
    </div>
  )
}

export default Leader
