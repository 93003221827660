import React from "react"
import "./Logo.css"

const Logo = () => {
  return (
    <div className="logo">
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="1054.000000pt"
        height="414.000000pt"
        viewBox="0 0 1054.000000 414.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,414.000000) scale(0.100000,-0.100000)"
          fill="#000000"
          stroke="none"
        >
          <path
            className="logo-mark"
            d="M2852 3868 c-23 -29 -178 -632 -272 -1063 -55 -253 -67 -294 -122
-420 -115 -267 -204 -386 -221 -297 -9 43 28 233 77 404 25 87 46 171 46 186
0 17 -10 38 -25 52 -39 37 -93 24 -300 -72 -12 -5 -30 -34 -44 -71 -42 -110
-109 -241 -141 -274 -34 -36 -76 -51 -93 -34 -32 32 -9 228 47 406 24 76 21
95 -15 95 -19 0 -19 1 -66 -145 -49 -156 -104 -264 -162 -324 -105 -108 -191
-67 -191 92 0 181 118 415 242 478 59 30 101 25 148 -17 41 -38 70 -40 70 -6
0 26 -35 69 -72 88 -182 94 -447 -220 -448 -532 0 -123 44 -206 116 -220 77
-14 144 22 215 116 l36 48 13 -55 c15 -69 38 -93 89 -93 80 0 152 85 236 278
l49 113 85 40 c93 45 151 60 151 39 0 -7 -22 -95 -50 -194 -79 -285 -91 -411
-47 -467 49 -62 141 0 221 149 36 68 42 64 26 -18 -6 -34 -5 -48 5 -59 24 -25
42 -4 70 81 30 92 161 368 175 368 21 0 25 -37 31 -250 5 -235 14 -284 57
-316 72 -55 153 39 289 341 35 77 62 149 61 160 -2 12 -12 21 -24 23 -18 3
-27 -12 -64 -95 -96 -219 -188 -383 -216 -383 -30 0 -38 45 -45 266 -6 194 -9
227 -28 269 l-21 47 34 47 c49 66 141 153 157 149 8 -1 40 11 72 27 44 23 57
35 57 53 0 12 -6 22 -12 23 -69 6 -140 -27 -222 -102 -62 -56 -172 -206 -227
-306 -18 -34 -34 -61 -35 -60 -4 3 51 266 96 462 54 237 76 325 167 667 41
158 73 294 70 302 -8 19 -31 21 -45 4z"
          />
          <path
            className="logo-djamilla"
            d="M9301 3854 c-89 -63 -307 -741 -390 -1218 -28 -158 -34 -177 -86
-282 -31 -62 -73 -132 -95 -156 -38 -41 -40 -42 -54 -24 -40 51 -25 254 40
521 43 179 44 184 22 198 -34 22 -45 -8 -98 -260 -26 -126 -38 -158 -96 -274
-36 -72 -82 -156 -102 -187 -36 -54 -37 -55 -40 -27 -5 43 12 119 84 378 96
346 101 389 49 423 -35 23 -87 3 -153 -60 -57 -54 -148 -169 -188 -239 -28
-49 -28 -45 -9 43 20 92 19 133 -6 164 -24 30 -64 34 -108 11 -41 -21 -117
-109 -168 -195 -51 -86 -51 -85 -1 117 32 130 31 156 -4 151 -18 -2 -25 -17
-45 -91 -12 -49 -37 -157 -54 -240 -34 -166 -64 -237 -126 -301 -47 -48 -83
-47 -97 3 -14 48 7 193 47 334 34 119 34 137 -5 137 -13 0 -26 -28 -58 -129
-52 -165 -110 -282 -171 -343 -104 -104 -189 -62 -189 95 0 181 118 415 242
478 59 30 101 25 148 -17 60 -54 99 -23 49 40 -104 132 -311 35 -425 -200 -25
-50 -50 -118 -57 -150 -19 -88 -50 -137 -147 -238 l-88 -91 23 105 c43 189
106 542 101 557 -4 8 -15 13 -28 11 -18 -2 -25 -12 -31 -43 -49 -262 -103
-529 -122 -607 l-23 -98 -84 -89 c-161 -168 -252 -308 -297 -455 -33 -108 -24
-174 29 -196 120 -50 245 139 364 550 l45 155 119 121 120 120 16 -52 c49
-156 198 -153 317 7 l38 49 11 -56 c14 -68 38 -94 86 -94 40 0 68 12 104 46
l26 24 -1 -28 c0 -22 4 -27 24 -27 22 0 28 10 62 109 90 264 244 505 305 482
22 -8 14 -59 -42 -268 -25 -92 -47 -190 -51 -217 -5 -43 -3 -50 16 -61 27 -14
46 -1 56 38 26 104 125 293 223 426 67 90 155 171 174 160 17 -11 1 -89 -63
-310 -100 -345 -118 -453 -83 -502 39 -56 105 -7 185 137 l46 82 6 -61 c11
-97 42 -140 101 -140 32 0 82 49 132 130 41 67 52 65 54 -10 1 -48 33 -115 64
-135 63 -41 151 21 237 168 l46 78 6 -79 c6 -94 28 -147 70 -169 66 -34 147
26 235 174 l48 81 26 -59 c21 -48 33 -62 62 -74 46 -19 90 -19 131 1 33 15
103 84 137 134 15 23 17 23 18 5 0 -11 5 -37 11 -58 31 -114 148 -102 231 25
78 117 97 183 53 183 -14 0 -30 -21 -60 -79 -63 -120 -138 -177 -165 -127 -24
46 -4 208 46 373 29 93 27 121 -8 116 -18 -3 -28 -23 -63 -133 -69 -212 -140
-334 -221 -377 -83 -44 -134 8 -134 137 1 190 111 409 239 474 62 32 106 28
148 -14 39 -39 66 -42 71 -9 4 26 -31 70 -71 90 -148 77 -352 -105 -427 -381
-41 -148 -186 -400 -246 -427 -56 -26 -81 55 -71 227 l7 101 113 264 c289 670
391 1004 338 1104 -17 31 -53 43 -85 28 -89 -40 -317 -735 -400 -1222 -24
-139 -28 -150 -91 -269 -89 -169 -158 -251 -198 -235 -30 11 -39 70 -35 208
l5 131 102 235 c245 561 355 882 356 1043 0 71 -2 81 -24 98 -29 23 -49 24
-80 3z m45 -116 c-13 -129 -92 -378 -211 -668 -118 -286 -129 -312 -133 -302
-5 17 47 234 103 431 68 235 125 400 181 519 50 108 70 115 60 20z m351 -5
c-9 -134 -96 -398 -252 -773 -88 -213 -98 -232 -89 -175 29 183 217 801 284
936 57 113 65 115 57 12z m-2906 -1784 c-69 -255 -157 -436 -230 -474 -63 -32
-76 5 -40 121 28 91 55 142 124 239 55 76 157 195 163 190 1 -1 -6 -36 -17
-76z"
          />
          <path
            className="logo-mark"
            d="M1255 3488 c-115 -205 -274 -501 -353 -658 -79 -157 -145 -286 -146
-288 -5 -5 5 205 24 498 16 243 25 491 21 583 -1 11 -9 17 -25 17 -21 0 -30
-14 -71 -112 -70 -166 -143 -366 -221 -600 -38 -114 -70 -206 -72 -204 -5 5
73 338 138 595 46 179 58 237 49 247 -13 16 -19 17 -36 6 -14 -9 -166 -597
-213 -827 -47 -224 -100 -528 -94 -537 3 -4 16 -8 29 -8 21 0 27 11 58 108
234 716 371 1110 393 1132 4 3 3 -38 0 -90 -4 -52 -15 -229 -26 -393 -31 -459
-26 -582 23 -575 16 2 38 39 103 178 46 96 125 257 177 358 109 208 360 663
364 658 2 -2 -17 -79 -42 -172 -111 -415 -200 -826 -249 -1144 -35 -228 -35
-234 -12 -238 10 -2 22 0 28 6 5 5 22 94 39 198 59 381 169 866 308 1366 69
245 72 268 38 268 -19 0 -52 -53 -232 -372z"
          />
          <path
            className="logo-djamilla"
            d="M6719 3797 c-71 -20 -130 -60 -200 -135 -113 -119 -223 -299 -316
-516 -37 -86 -39 -121 -9 -121 22 0 15 -12 111 189 162 343 328 525 484 529
61 2 83 -15 112 -92 62 -160 20 -498 -102 -818 -74 -196 -147 -319 -258 -436
-91 -95 -168 -138 -260 -144 -86 -6 -124 17 -164 100 -28 57 -45 69 -65 44
-30 -36 40 -152 113 -187 39 -19 58 -22 118 -17 40 2 92 14 117 25 192 85 371
337 491 692 22 65 32 84 45 82 9 -2 28 8 43 21 14 14 65 50 114 82 61 39 87
62 87 76 0 11 -6 22 -14 25 -14 6 -162 -80 -205 -119 l-24 -22 7 35 c64 310
63 500 -4 629 -38 76 -123 106 -221 78z"
          />
          <path
            className="logo-djamilla"
            d="M6243 3612 c-31 -19 -222 -824 -293 -1231 -29 -168 -31 -185 -16
-199 14 -14 18 -14 31 -2 9 9 49 188 104 460 89 445 108 528 182 801 21 81 39
150 39 153 0 11 -36 25 -47 18z"
          />
          <path
            className="logo-heart"
            d="M5270 3517 c0 -16 -106 -21 -116 -6 -3 6 -16 4 -31 -6 -13 -9 -31
-14 -39 -11 -7 3 -18 -3 -24 -13 -10 -20 -66 -44 -81 -35 -27 16 -180 -117
-259 -227 -68 -93 -118 -183 -124 -217 -2 -19 -7 -37 -10 -40 -9 -8 -31 25
-45 67 -28 83 -132 289 -163 323 -15 16 -30 37 -33 47 -3 10 -23 30 -45 45
-30 20 -50 25 -95 25 -45 -1 -54 -4 -45 -14 13 -16 -1 -28 -23 -20 -8 3 -39
-8 -70 -25 -30 -16 -65 -30 -76 -30 -12 0 -21 -4 -21 -10 0 -5 -11 -15 -24
-22 -20 -11 -42 -35 -104 -113 -50 -62 -88 -244 -61 -290 3 -5 7 -27 8 -47 3
-55 37 -159 66 -202 14 -20 25 -40 25 -43 0 -10 81 -133 120 -183 41 -53 153
-162 228 -222 29 -24 84 -69 122 -100 76 -62 106 -76 123 -59 8 8 -1 23 -36
55 -28 27 -42 46 -34 49 9 4 9 6 0 6 -7 1 -13 7 -13 15 0 8 -39 59 -87 113
-154 176 -290 360 -346 470 -33 64 -60 186 -61 278 -1 80 2 96 26 142 27 51
53 75 117 108 26 13 46 15 92 10 79 -9 150 -54 211 -132 27 -35 46 -63 44 -63
-3 0 6 -16 19 -36 26 -39 119 -223 120 -238 0 -5 8 -14 17 -19 27 -18 64 6 72
45 9 47 145 283 198 342 57 64 83 85 157 128 107 63 165 83 236 81 33 -1 67
-3 75 -5 26 -6 118 -106 125 -136 3 -17 -2 -57 -13 -98 -26 -90 -116 -289
-153 -335 -16 -20 -29 -42 -29 -47 0 -12 -85 -141 -104 -157 -6 -5 -18 -23
-26 -39 -27 -54 -178 -257 -237 -320 -106 -113 -203 -224 -203 -234 0 -16 -36
-42 -59 -42 -20 0 -22 -4 -16 -29 5 -24 -1 -40 -29 -80 -39 -55 -44 -71 -22
-71 20 0 76 49 76 65 0 8 12 23 26 34 14 11 42 42 61 68 54 72 156 190 223
260 33 34 60 67 60 73 0 5 5 10 10 10 6 0 14 8 17 18 4 9 15 26 25 37 20 23
121 156 188 249 25 35 57 77 72 94 15 18 29 41 33 52 3 11 22 43 40 70 19 28
39 66 46 85 7 19 16 35 20 35 3 0 12 21 19 48 7 26 19 57 27 70 9 15 15 55 15
115 2 116 -20 173 -81 211 -22 14 -45 26 -51 26 -15 0 -12 30 3 30 18 1 -35
29 -55 30 -10 0 -18 -6 -18 -13z m-1004 -78 c13 -22 -12 -29 -44 -13 -18 8
-32 17 -32 20 0 9 69 3 76 -7z"
          />
          <path
            className="logo-djamilla"
            d="M8809 3163 c-92 -62 -130 -98 -124 -116 12 -31 35 -25 102 24 37 28
81 58 96 67 18 11 27 24 25 37 -5 32 -39 28 -99 -12z"
          />
          <path
            className="logo-heart"
            d="M4510 2056 c0 -19 4 -24 15 -19 18 6 16 34 -2 41 -8 2 -13 -6 -13
-22z"
          />
          <path
            className="logo-date"
            d="M5552 1088 c-104 -16 -252 -103 -252 -149 0 -10 3 -19 7 -19 3 0 36
20 72 45 109 74 229 106 265 69 59 -59 -49 -260 -236 -440 -113 -108 -254
-202 -338 -223 -71 -19 -100 -32 -100 -48 0 -24 31 -27 102 -8 82 21 133 16
221 -21 97 -40 99 -40 95 -13 -2 19 -16 29 -73 51 -38 15 -78 29 -88 32 -20 6
-15 12 158 151 238 192 382 449 303 540 -30 33 -70 43 -136 33z"
          />
          <path
            className="logo-date"
            d="M6810 1089 c-61 -9 -124 -35 -188 -77 -58 -38 -80 -68 -64 -84 7 -7
26 1 60 26 67 49 150 86 210 93 42 5 53 2 72 -17 18 -18 20 -28 15 -63 -34
-200 -382 -543 -611 -603 -62 -16 -91 -35 -80 -53 10 -16 56 -14 107 5 61 22
145 12 231 -28 49 -22 72 -28 79 -21 17 17 -20 47 -85 72 -31 11 -63 21 -71
21 -35 0 -25 10 140 143 221 177 372 411 336 520 -19 58 -68 79 -151 66z"
          />
          <path
            className="logo-date"
            d="M3193 1059 c-197 -97 -368 -453 -313 -651 7 -23 25 -49 48 -68 74
-61 172 -32 287 85 79 80 123 150 172 268 30 75 33 91 33 188 0 94 -3 110 -23
140 -12 18 -34 39 -47 46 -38 19 -110 16 -157 -8z m151 -49 c89 -107 -13 -415
-193 -583 -118 -110 -210 -100 -226 25 -23 180 124 464 290 559 65 37 96 37
129 -1z"
          />
          <path
            className="logo-date"
            d="M3878 1043 c-62 -50 -117 -106 -112 -115 8 -13 41 -9 54 7 33 40 13
-5 -71 -156 -113 -204 -229 -434 -229 -454 0 -20 23 -19 42 3 9 9 35 55 58
102 24 47 89 168 145 270 122 220 175 324 175 344 0 26 -29 25 -62 -1z"
          />
          <path
            className="logo-date"
            d="M4761 1008 c-39 -34 -71 -66 -71 -71 0 -17 32 -18 50 -2 11 10 20 14
20 10 0 -4 -39 -78 -87 -164 -107 -193 -233 -440 -233 -458 0 -7 9 -13 19 -13
14 0 41 41 109 167 49 93 135 251 191 353 98 178 115 227 85 237 -7 2 -44 -24
-83 -59z"
          />
          <path
            className="logo-date"
            d="M7485 1045 c-72 -25 -140 -67 -159 -97 -17 -29 3 -34 42 -11 98 59
164 85 214 87 35 1 52 -3 56 -13 8 -22 -100 -113 -202 -170 -96 -53 -116 -77
-73 -85 12 -2 49 -9 82 -15 72 -13 105 -36 124 -85 21 -55 2 -107 -62 -171
-98 -97 -281 -164 -379 -138 -45 12 -58 10 -58 -11 0 -33 104 -50 194 -31 136
29 292 129 337 217 55 108 -2 226 -125 259 l-39 11 69 41 c97 58 174 135 174
174 0 67 -73 81 -195 38z"
          />
          <path
            className="logo-date"
            d="M4062 633 c-13 -2 -21 -10 -19 -16 3 -9 46 -12 145 -12 132 0 165 5
148 23 -9 8 -238 13 -274 5z"
          />
          <path
            className="logo-date"
            d="M5837 633 c-16 -3 -26 -10 -24 -16 4 -14 218 -22 277 -12 33 6 34 8
15 21 -14 10 -48 14 -129 13 -61 -1 -124 -4 -139 -6z"
          />
        </g>
      </svg>
    </div>
  )
}
export default Logo
