import React from "react"
import "./Home.css"
import Page from "../components/Page"

function Home(): ReturnType<React.FC> {
  return (
    <Page title="">
      <div className="home">
        <p className="paragraph">
          Welkom op onze bruiloft. Hier boven kun je digitale spelletjes
          selecteren.
        </p>
        <div className="animation">
          <div className="animation-date">
            <span className="animation-date-1">0</span>
            <span className="animation-date-2">1</span>
            <span className="animation-date-2-2">1</span>
            <span className="animation-date-3">2</span>
            <span className="animation-date-3-2">2</span>
            <span className="animation-date-4">3</span>
          </div>
          <span className="animation-heart">
            <span className="animation-heart-icon">
              <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="190.000000pt"
                height="190.000000pt"
                viewBox="0 0 190.000000 190.000000"
                preserveAspectRatio="xMidYMid meet"
              >
                <g
                  transform="translate(0.000000,190.000000) scale(0.100000,-0.100000)"
                  fill="#000000"
                  stroke="none"
                >
                  <path
                    className="svg-heart"
                    d="M1506 1615 c-6 -17 -41 -20 -51 -6 -3 6 -24 3 -51 -6 -25 -8 -43 -18
-39 -21 8 -8 -55 -33 -66 -26 -25 15 -149 -90 -207 -176 -20 -30 -40 -57 -44
-60 -18 -14 -78 -126 -78 -146 0 -13 -4 -25 -9 -28 -10 -6 -34 19 -37 39 -3
24 -81 190 -110 236 -16 25 -39 62 -52 82 -33 51 -77 76 -134 75 -36 -1 -45
-4 -38 -13 12 -14 3 -26 -15 -19 -7 3 -30 -5 -51 -17 -22 -12 -49 -23 -61 -24
-63 -8 -173 -145 -193 -240 -15 -68 -20 -145 -10 -145 4 0 7 -15 8 -33 2 -51
40 -162 62 -182 11 -10 20 -24 20 -31 0 -7 27 -53 59 -101 55 -82 168 -202
249 -263 19 -14 66 -51 105 -82 39 -32 77 -58 84 -58 29 0 22 24 -16 60 -26
24 -36 40 -28 43 9 4 9 6 0 6 -7 1 -13 6 -13 11 0 6 -43 60 -95 121 -186 216
-286 368 -309 467 -36 165 -30 249 26 316 45 54 92 75 153 67 123 -17 188 -87
311 -339 25 -50 51 -92 58 -92 22 0 43 24 56 62 6 20 21 52 33 70 12 18 35 58
51 88 16 30 37 64 47 76 11 11 19 24 19 28 0 23 115 112 198 153 84 43 100 47
160 47 66 -1 68 -2 114 -47 54 -52 66 -91 48 -158 -15 -58 -106 -256 -136
-296 -13 -17 -24 -34 -24 -37 0 -11 -68 -112 -84 -125 -9 -8 -24 -30 -33 -50
-25 -54 -116 -173 -221 -291 -146 -163 -152 -170 -152 -179 0 -13 -35 -41 -52
-41 -9 0 -18 -7 -22 -15 -3 -8 -1 -15 4 -15 18 0 10 -21 -25 -67 -24 -32 -32
-50 -25 -57 13 -13 60 21 77 55 7 13 18 28 26 34 7 5 29 30 48 55 19 25 43 54
54 66 11 11 34 38 50 59 17 21 54 63 83 92 28 30 52 57 52 61 0 4 12 21 28 38
27 30 43 51 86 114 13 19 38 50 55 68 17 18 31 37 31 42 0 4 20 32 44 62 24
29 55 76 70 103 15 28 39 70 54 95 71 120 98 275 64 362 -14 36 -65 85 -105
102 -9 3 -8 7 2 14 11 8 10 12 -7 21 -29 16 -52 14 -52 -5 0 -9 -6 -14 -12
-11 -7 2 -12 10 -10 16 3 17 -15 13 -22 -4z m-826 -75 c0 -20 -1 -20 -30 -5
-16 9 -30 18 -30 20 0 3 14 5 30 5 23 0 30 -4 30 -20z"
                  />
                  <path
                    className="svg-heart"
                    d="M897 373 c-11 -10 -8 -43 3 -43 15 0 24 30 13 41 -5 5 -12 6 -16 2z"
                  />
                </g>
              </svg>
            </span>
          </span>
        </div>
      </div>
    </Page>
  )
}

export default Home
