import React, { CSSProperties, useEffect, useState } from "react"
import "./quiz.css"
import Page from "../components/Page"
import Game from "../components/Game"
import Leader from "../components/Leader"

const QuizData = [
  {
    id: 0,
    question: "Op welke datum spraken Mark en Djamilla voor het eerst af?",
    answers: {
      a: "01-12-2018",
      b: "07-04-2019",
      c: "01-12-2019",
      d: "07-04-2020",
    },
    correctAnswer: "A",
  },
  {
    id: 1,
    question: "Wat deden Mark en Djamilla op de eerste date?",
    answers: {
      a: "Schaatsen",
      b: "Wandelen",
      c: "Brownie eten",
      d: "Film kijken",
    },
    correctAnswer: "D",
  },
  {
    id: 2,
    question: "Naar welke film gingen Mark en Djamilla op de eerste date?",
    answers: {
      a: "Bohemian Rhapsody",
      b: "Vals",
      c: "Robin Hood",
      d: "A Star Is Born",
    },
    correctAnswer: "D",
  },
  {
    id: 3,
    question:
      "Welke koude locatie bezochten Mark en Djamilla tijdens een date?",
    answers: {
      a: "De schaatshal",
      b: "Een ijsbeelden hal",
      c: "Een indoor skipiste",
      d: "Het buitenzwembad",
    },
    correctAnswer: "B",
  },
  {
    id: 4,
    question: "Wat luste Mark niet voordat hij met Djamilla ging?",
    answers: {
      a: "Stokbrood met dip",
      b: "Boontjes",
      c: "Aardappelen",
      d: "Komkommer",
    },
    correctAnswer: "A",
  },
  {
    id: 5,
    question:
      "Wat was de eerste grote vakantie bestemming van Mark en Djamilla?",
    answers: {
      a: "Denenmarken",
      b: "Spanje",
      c: "Kroatië",
      d: "Portugal",
    },
    correctAnswer: "C",
  },
  {
    id: 6,
    question: "Hoe vaak is Djamilla mee geweest naar PEC Zwolle?",
    answers: {
      a: "Nooit",
      b: "1 keer",
      c: "3 keer",
      d: "Vaker dan 3 keer",
    },
    correctAnswer: "B",
  },
  {
    id: 7,
    question: "Wat vinden Mark en Djamilla beide lekker om te eten?",
    answers: {
      a: "Appeltaart",
      b: "Hazelnootschuimtaart",
      c: "Brownies",
      d: "Hartige taart",
    },
    correctAnswer: "C",
  },
  {
    id: 8,
    question: "Sinds wanneer wonen Mark en Djamilla aan de Cornelis Lelyhage?",
    answers: {
      a: "Sinds mei 2020",
      b: "Sinds juni 2020",
      c: "Sinds mei 2021",
      d: "Sinds juni 2021",
    },
    correctAnswer: "D",
  },
  {
    id: 9,
    question: "Wat staat er bij Mark en Djamilla op het menu op vrijdag?",
    answers: {
      a: "Friet",
      b: "Een kapsalon",
      c: "Pizza",
      d: "Iedere week wat anders",
    },
    correctAnswer: "D",
  },
  {
    id: 10,
    question: "Hoeveel eettafel stoelen hebben Mark en Djamilla",
    answers: {
      a: "4",
      b: "5",
      c: "6",
      d: "8",
    },
    correctAnswer: "C",
  },
  {
    id: 11,
    question: "Waar gaat Mark vroeg zijn bed voor uit?",
    answers: {
      a: "Om hard te lopen",
      b: "Om Vroege Vogels te kijken",
      c: "Om te fietsen",
      d: "Om Formule 1 te kijken",
    },
    correctAnswer: "D",
  },
  {
    id: 12,
    question:
      "Welk tv programma kijken Mark en Djamilla graag op vrijdag avond? ",
    answers: {
      a: "Flikken",
      b: "Masked Singer",
      c: "2 Voor 12",
      d: "De Geknipte Gast",
    },
    correctAnswer: "A",
  },
  {
    id: 13,
    question: "Wat vindt Djamilla het leukste aan Mark?",
    answers: {
      a: "Z'n humor",
      b: "Z'n ogen",
      c: "Z'n lach",
      d: "Z'n enthausiasme",
    },
    correctAnswer: "C",
  },
  {
    id: 14,
    question: "Waar gaan Mark en Djamilla naar toe op mini huwelijksreis? ",
    answers: {
      a: "Vlieland",
      b: "Maastricht ",
      c: "Texel",
      d: "Kopenhagen",
    },
    correctAnswer: "A",
  },
]

const orderedData = QuizData.map((value) => ({ value, sort: Math.random() }))
  .sort((a, b) => a.sort - b.sort)
  .map(({ value }, index) => ({ ...value, order: index }))

function Quiz(): ReturnType<React.FC> {
  const [progress, setProgress] = useState(0)
  const [score, setScore] = useState(12)
  const [answersData, setAnswersData] = useState<{ [id: number]: string }>({})

  useEffect(() => {
    setProgress(0)
    setScore(0)
    setAnswersData({})
  }, [])

  const nextButtonClick = () => {
    if (answersData[orderedData[progress]?.id] !== undefined) {
      setProgress(progress + 1)
      setScore(
        Object.keys(answersData)
          .map((id) => {
            const answerGiven = answersData[+id]
            const { correctAnswer = "" } =
              orderedData.find((o) => o.id === +id) || {}
            return (correctAnswer === answerGiven ? 1 : 0) as number
          })
          .reduce((partialSum, a) => partialSum + a, 0),
      )
    }
  }

  return (
    <Page title="Quizzzzz">
      <Game
        introductionText="Test je kennis over het bruidspaar.
        Kun jij alle vragen juist beantwoorden?
        Je kunt hulp vragen van anderen,  maar echte kenners kennen alle antwoorden 😉
        Beantwoord de vragen door op het juiste antwoord te klikken.
        Als je een antwoord hebt geselecteerd kun je via de 'volgende' knop naar de volgende vraag.
        Aan het einde van de quiz worden de correcte antwoorden opgeteld.
        "
        progress={progress}
        total={orderedData.length}
        resultScore={score}
      >
        <div className="quiz">
          <div className="quiz-question">{orderedData[progress]?.question}</div>
          <button
            className={`quiz-answer-button quiz-answers-button-A ${
              answersData[orderedData[progress]?.id] === "A"
                ? "quiz-answer-button-clicked"
                : ""
            }`}
            onClick={() => {
              const newData = { ...answersData }
              newData[orderedData[progress]?.id] = "A"

              setAnswersData(newData)
            }}
          >
            {orderedData[progress]?.answers.a
              ? `A: ${orderedData[progress]?.answers.a}`
              : ""}
          </button>
          <button
            className={`quiz-answer-button quiz-answers-button-B ${
              answersData[orderedData[progress]?.id] === "B"
                ? "quiz-answer-button-clicked"
                : ""
            }`}
            onClick={() => {
              const newData = { ...answersData }
              newData[orderedData[progress]?.id] = "B"

              setAnswersData(newData)
            }}
          >
            {orderedData[progress]?.answers.b
              ? `B: ${orderedData[progress]?.answers.b}`
              : ""}
          </button>
          <button
            className={`quiz-answer-button quiz-answers-button-C ${
              answersData[orderedData[progress]?.id] === "C"
                ? "quiz-answer-button-clicked"
                : ""
            }`}
            onClick={() => {
              const newData = { ...answersData }
              newData[orderedData[progress]?.id] = "C"

              setAnswersData(newData)
            }}
          >
            {orderedData[progress]?.answers.c
              ? `C: ${orderedData[progress]?.answers.c}`
              : ""}
          </button>
          <button
            className={`quiz-answer-button quiz-answers-button-D ${
              answersData[orderedData[progress]?.id] === "D"
                ? "quiz-answer-button-clicked"
                : ""
            }`}
            onClick={() => {
              const newData = { ...answersData }
              newData[orderedData[progress]?.id] = "D"

              setAnswersData(newData)
            }}
          >
            {orderedData[progress]?.answers.d
              ? `D: ${orderedData[progress]?.answers.d}`
              : ""}
          </button>
          <div
            className={`buttongroup ${
              !!answersData[orderedData[progress]?.id]
                ? "buttongroup-answer-given"
                : ""
            }`}
          >
            <button className={`button`} onClick={() => nextButtonClick()}>
              {progress >= orderedData.length - 1 ? "Klaar!" : "Volgende"}
            </button>
          </div>
        </div>
      </Game>
      <Leader
        leaderTitleStrings={["¿", "Quizzzzz", "?"]}
        leaderContentNodes={[
          <img alt="questionmark" src="/questionmark.png" width="150" />,
        ]}
      ></Leader>
    </Page>
  )
}

export default Quiz
