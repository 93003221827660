import React, { useEffect, useState } from "react"
import "./KruisWoordPuzzel.css"
import Page from "../components/Page"
import { cloneDeep } from "lodash"

import Crossword from "@jaredreisinger/react-crossword"
import Leader from "../components/Leader"
import Game from "../components/Game"

const data: {
  down: {
    [clueIndex: number]: {
      clue: string
      answer: string
      row: number
      col: number
    }
  }
  across: {
    [clueIndex: number]: {
      clue: string
      answer: string
      row: number
      col: number
    }
  }
} = {
  across: {
    1: {
      clue: "In deze plaats werd Djamilla ten huwelijk gevraagd",
      answer: "EMMELOORD",
      row: 0,
      col: 3,
    },
    4: {
      clue: "Deze sport doet Mark samen met zijn schoonvader",
      answer: "HARDLOPEN",
      row: 2,
      col: 3,
    },
    7: {
      clue: "Marks favoriete restaurant",
      answer: "LOETJE",
      row: 3,
      col: 13,
    },
    9: {
      clue: "Locatie van de eerste date van het bruidspaar",
      answer: "ZWOLLE",
      row: 5,
      col: 4,
    },
    10: {
      clue: "Deze keur verpakking kiest Djamilla altijd voor M&M's",
      answer: "BLAUW",
      row: 5,
      col: 14,
    },
    11: {
      clue: "Dit eet Djamilla graag in de vakantie",
      answer: "SCHEPIJS",
      row: 7,
      col: 1,
    },
    12: {
      clue: "Deze zuidelijke plaats bezoeken Mark & Djamilla ieder jaar samen",
      answer: "MAASTRICHT",
      row: 7,
      col: 10,
    },
    13: {
      clue: "Dit zei het bruidspaar vanmiddag",
      answer: "JA",
      row: 8,
      col: 20,
    },
    15: {
      clue: "De bruid",
      answer: "DJAMILLA",
      row: 9,
      col: 13,
    },
    19: {
      clue: "De bruidegom",
      answer: "MARK",
      row: 11,
      col: 1,
    },
    20: {
      clue: "Deze gebouwen beklimt Mark vaak tijdens vakanties",
      answer: "UITKIJKTORENS",
      row: 11,
      col: 6,
    },
    24: {
      clue: "Favoriete biermerk van Mark",
      answer: "TEXELS",
      row: 15,
      col: 13,
    },
    25: {
      clue: "Waar gaat het bruidspaar morgen overnachten?",
      answer: "VLIELAND",
      row: 16,
      col: 0,
    },
    26: {
      clue: "Na .... maanden kochten Mark en Djamilla samen een huis",
      answer: "VIER",
      row: 16,
      col: 10,
    },
    27: {
      clue: "Deze keur verpakking kiest Mark altijd voor M&M's",
      answer: "BRUIN",
      row: 18,
      col: 2,
    },
    28: {
      clue: "Tijdens dit evenement verstopt het bruidspaar eieren met een app",
      answer: "PASEN",
      row: 19,
      col: 11,
    },
  },
  down: {
    2: {
      clue: "Op deze dag doen Mark en Djamilla bijna altijd boodschappen",
      answer: "DONDERDAG",
      row: 0,
      col: 11,
    },
    3: {
      clue: "Favoriete (suikervrije) drankje van Djamilla",
      answer: "COLA",
      row: 1,
      col: 13,
    },
    5: {
      clue: "Werkgever van Mark",
      answer: "DIVOTION",
      row: 2,
      col: 6,
    },
    6: {
      clue: "De trouwlocatie",
      answer: "WELLERWAARD",
      row: 2,
      col: 15,
    },
    8: {
      clue: "Cornelis Lelyhage ...",
      answer: "ZES",
      row: 4,
      col: 9,
    },
    13: {
      clue: "Dit land staat boven aan Marks bucketlist",
      answer: "JAPAN",
      row: 8,
      col: 20,
    },
    14: {
      clue: "Favoriete game van Djamilla",
      answer: "PIKMIN",
      row: 9,
      col: 9,
    },
    16: {
      clue: "Dit bakken Mark & Djamilla graag samen",
      answer: "BROWNIE",
      row: 10,
      col: 3,
    },
    17: {
      clue: "Favoriete game merk van Mark",
      answer: "NINTENDO",
      row: 10,
      col: 7,
    },
    18: {
      clue: "Broer van Djamilla",
      answer: "BJÖRN",
      row: 10,
      col: 11,
    },
    21: {
      clue: "Mark speel geregeld deze racketsport",
      answer: "SQUASH",
      row: 11,
      col: 18,
    },
    22: {
      clue: "Naar dit land ging het bruidspaar afgelopen zomer op vakantie",
      answer: "SLOVENIË",
      row: 13,
      col: 0,
    },
    23: {
      clue: "Hier betaalden Mark en Djamilla met Kuna's",
      answer: "KROATIË",
      row: 13,
      col: 5,
    },
    24: {
      clue: "Huwelijksreis locatie",
      answer: "TROMSØ",
      row: 15,
      col: 13,
    },
  },
}

function KruisWoordPuzzel(): ReturnType<React.FC> {
  const [answersState, setAnswersState] = useState<{
    [directionIndexKey: string]: boolean
  }>({})

  const updateAnswerInState = (
    directionKey: string,
    clueIndex: string,
    correct: boolean,
  ) => {
    const newAnswerState = cloneDeep(answersState)
    newAnswerState[`${directionKey}-${clueIndex}`] = correct
    setAnswersState(newAnswerState)
  }

  const initAnswersState = () => {
    setAnswersState(
      Object.keys(data).reduce(
        (
          answersState: {
            [directionClueIndex: string]: boolean
          },
          directionKey,
        ) => {
          if (directionKey === "down" || directionKey === "across") {
            Object.keys(data[directionKey]).forEach((clueIndex) => {
              answersState[`${directionKey}-${clueIndex}`] = false
            }, {})
          }
          return answersState
        },
        {},
      ),
    )
  }
  useEffect(() => initAnswersState(), [])

  return (
    <Page title="Kruiswoord-puzzel">
      <Game
        introductionText="Vind jij de juiste verticale en horizontale (ant)woorden?
        Je kan anderen om hulp vragen, maar als je alle (ant)woorden zelf kunt bedenken is dat wel knapper. 
        Als je op een vakje klikt kun je de letters invoeren. De letters van het gevraagde woord worden dan licht geel. Bovenaan het scherm staat de vraag.
        In de voortgangsbalk kun je bijhouden hoeveel goede antwoorden je hebt gegeven.
        Gebruik indien nodig speciale karakters als ö of ø of ë in plaats van o en e.
        Let op: soms scrollt het scherm automatisch naar boven of beneden als het virtuele toetsenbord wordt geopent.
      "
        total={Object.values(answersState).length}
        progress={Object.values(answersState).filter((b) => b).length}
        resultComplete={true}
      >
        <div className="kruiswoordpuzzel">
          <Crossword
            data={data}
            onAnswerComplete={(direction, number, correct, answer) => {
              updateAnswerInState(direction, number, correct)
            }}
          />
        </div>
      </Game>
      <Leader
        leaderTitleStrings={["Kruis", "woord", "puzzel", "!"]}
        leaderContentNodes={[<img alt="23" src="/23.png" width="120" />]}
      ></Leader>
    </Page>
  )
}

export default KruisWoordPuzzel
