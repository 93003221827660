import React, { PropsWithChildren } from "react"
import "./Page.css"
import Logo from "./Logo"
import Navigation from "./Navigation"

interface PageProps {
  title?: string
}

function Page({
  children,
  title,
}: PropsWithChildren<PageProps>): ReturnType<React.FC> {
  return (
    <div className="screen">
      <div className="page">
        <header className="page-header">
          <Navigation />
          <div className="page-header-row">
            <Logo />
            {title && <h1 className="page-header-title">{title}</h1>}
          </div>
          <div className="page-line" />
        </header>
        <section className="page-section">{children}</section>
        <footer className="page-footer">
          <div className="page-line" />
        </footer>
      </div>
    </div>
  )
}

export default Page
