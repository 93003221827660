import React, { PropsWithChildren, useState, useEffect } from "react"
import "./Game.css"
import Result from "./Result"

interface GameProps {
  introductionText: string
  progress: number
  total: number
  resultScore?: number
  resultComplete?: boolean
}
type gameState =
  | "introductionShown"
  | "introductionRead"
  | "gameShown"
  | "gameFinishing"
  | "gameFinished"
  | "resultShown"

function Game({
  children,
  introductionText,
  progress,
  total,
  resultScore,
  resultComplete,
}: PropsWithChildren<GameProps>): ReturnType<React.FC> {
  const [state, setState] = useState<gameState>("introductionShown")

  useEffect(() => {
    if (state === "introductionRead") {
      const timeId = setTimeout(() => {
        setState("gameShown")
      }, 2000)

      return () => {
        clearTimeout(timeId)
      }
    } else if (state === "gameShown" && progress === total) {
      setState("gameFinishing")
    } else if (state === "gameFinishing") {
      const timeId = setTimeout(() => {
        setState("gameFinished")
      }, 2000)
      return () => {
        clearTimeout(timeId)
      }
    } else if (state === "gameFinished") {
      const timeId = setTimeout(() => {
        setState("resultShown")
      }, 2000)

      return () => {
        clearTimeout(timeId)
      }
    }
  }, [progress, total, state])

  return (
    <div className="game">
      {["introductionShown", "introductionRead"].includes(state) && (
        <div
          className={`game-introduction 
          ${state === "introductionRead" ? "game-animation-hide" : ""}
          `}
        >
          {introductionText.split("\n").map((str) => (
            <p className="paragraph">{str}</p>
          ))}
          <button
            className={`button game-introduction-button ${
              state === "introductionRead"
                ? "game-introduction-button-clicked"
                : ""
            }`}
            onClick={() => {
              setState("introductionRead")
            }}
          >
            Speel het spel!
          </button>
        </div>
      )}
      {["gameShown", "gameFinishing", "gameFinished", "resultShown"].includes(
        state,
      ) && (
        <div className="game-progress">
          <progress
            className="game-progress-bar game-animation-show"
            value={progress}
            max={total}
          ></progress>
        </div>
      )}
      {["gameShown", "gameFinishing"].includes(state) && (
        <div
          className={`game-content 
        ${state === "gameShown" ? "game-animation-show" : ""}
        ${state === "gameFinishing" ? "game-animation-hide" : ""}
        `}
        >
          {children}
        </div>
      )}
      {["gameFinished", "resultShown"].includes(state) && (
        <div
          className={`game-result 
        ${state === "gameFinished" ? "game-animation-show" : ""}
        `}
        >
          <Result score={resultScore} total={total} complete={resultComplete} />
        </div>
      )}
    </div>
  )
}

export default Game
