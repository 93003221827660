import React from "react"
import { Route, BrowserRouter as Router, Routes } from "react-router-dom"
import WieZegtWat from "../pages/WieZegtWat"
import KruisWoordPuzzel from "../pages/KruisWoordPuzzel"
import Quiz from "../pages/Quiz"
import Home from "../pages/Home"

const ApplicationRouter: React.FC = () => (
  <Router>
    <Routes>
      <Route key={`route-kwp`} path="/kwp" element={<KruisWoordPuzzel />} />
      <Route key={`route-wzw`} path="/wzw" element={<WieZegtWat />} />
      <Route key={`route-quiz`} path="/quiz" element={<Quiz />} />
      <Route key={`route-/`} path="/" element={<Home />} />
      <Route key={`route-*`} path="*" element={<Home />} />
    </Routes>
  </Router>
)

export default ApplicationRouter
