import React, { useEffect, useState } from "react"
import "./WieZegtWat.css"
import Page from "../components/Page"
import Game from "../components/Game"
import Leader from "../components/Leader"

const wieZegtWatData = [
  { id: 0, quote: "Ik maai graag het gras.", correctAnswer: 1 },
  { id: 1, quote: "Ik haal vaak onkruid uit de tuin.", correctAnswer: 0 },
  { id: 2, quote: "Ik heb de huwelijksreis geboekt.", correctAnswer: 1 },
  { id: 3, quote: "Jij kan beter koken dan ik.", correctAnswer: 1 },
  { id: 4, quote: "Liefie, kun je...", correctAnswer: 0 },
  { id: 5, quote: "Meisje, heb je...", correctAnswer: 1 },
  { id: 6, quote: "Jij bent heel creatief.", correctAnswer: 0 },
  { id: 7, quote: "Bij welke was moet dit shirt?", correctAnswer: 1 },
  { id: 8, quote: "Kom je zo ook naar bed?", correctAnswer: 0 },
  { id: 9, quote: "Ik vind het leuk om te bakken.", correctAnswer: 0 },
  { id: 10, quote: "Met kerst maak ik graag de desserts.", correctAnswer: 0 },
  { id: 11, quote: "Hoe laat lag je in bed gisteren?", correctAnswer: 0 },
  {
    id: 12,
    quote: "Wat heb je mooie keultjes in je wangen.",
    correctAnswer: 0,
  },
  {
    id: 13,
    quote: "Jij kunt wel mijn persoonlijke fotograaf worden.",
    correctAnswer: 1,
  },
  {
    id: 14,
    quote: "Ik hou er niet van om onder de plak te zitten.",
    correctAnswer: 1,
  },
  { id: 15, quote: "Je benen voelen als schuurpapier.", correctAnswer: 0 },
  {
    id: 16,
    quote: "Ik bewaar m'n stukje vlees voor het laatst.",
    correctAnswer: 1,
  },
  {
    id: 17,
    quote: "Je bent wel erg zorgzaam voor die boom.",
    correctAnswer: 0,
  },
  { id: 18, quote: "Jij maakt betere foto's.", correctAnswer: 0 },
  {
    id: 19,
    quote: "Je moet ook leuke dingen doen van je geld.",
    correctAnswer: 1,
  },
  { id: 20, quote: "Ik zou nog graag een keer kamperen.", correctAnswer: 0 },
  { id: 21, quote: "Jij bent een luxe paard.", correctAnswer: 0 },
  {
    id: 22,
    quote: "Ik zal de vuilnis even aan de weg zetten.",
    correctAnswer: 1,
  },
  { id: 23, quote: "Jouw wekker maakt te veel herrie.", correctAnswer: 0 },
]

const orderedData = wieZegtWatData
  .map((value) => ({ value, sort: Math.random() }))
  .sort((a, b) => a.sort - b.sort)
  .map(({ value }, index) => ({ ...value, order: index }))

function WieZegtWat(): ReturnType<React.FC> {
  const [progress, setProgress] = useState(0)
  const [score, setScore] = useState(0)
  const [answersData, setAnswersData] = useState<{ [id: number]: number }>({})

  useEffect(() => {
    setProgress(0)
    setScore(0)
    setAnswersData({})
  }, [])

  const nextButtonClick = () => {
    if (answersData[orderedData[progress]?.id] !== undefined) {
      setProgress(progress + 1)
      setScore(
        Object.keys(answersData)
          .map((id) => {
            const answerGiven = answersData[+id]
            const { correctAnswer = -1 } =
              orderedData.find((o) => o.id === +id) || {}
            return (correctAnswer === answerGiven ? 1 : 0) as number
          })
          .reduce((partialSum, a) => partialSum + a, 0),
      )
    }
  }
  return (
    <Page title="Wie Zegt Wat ?">
      <Game
        introductionText="In dit spel worden verschillende uitspraken van Mark en van Djamilla getoond.
        Kies jij de juiste persoon bij de uitspraken?
        Gokken kan natuurlijk ook, de kans op een juist antwoord is 50%.
        Beantwoord de vragen door op het hoofd van de juiste persoon te klikken.
        Als je een persoon hebt geselecteerd kun je via de 'volgende' knop naar de volgende vraag.
        Aan het einde van het spel worden de correcte antwoorden opgeteld.
        "
        progress={progress}
        total={orderedData.length}
        resultScore={score}
      >
        <div className="wie-zegt-wat">
          <img
            alt="person1"
            className={`person-img person-img-left ${
              answersData[orderedData[progress]?.id] === 0
                ? "person-img-left-clicked"
                : ""
            }`}
            src="/djamillaHoofd.png"
            onClick={() => {
              const newData = { ...answersData }
              newData[orderedData[progress]?.id] = 0

              setAnswersData(newData)
            }}
          />
          <img
            alt="person2"
            className={`person-img person-img-right ${
              answersData[orderedData[progress]?.id] === 1
                ? "person-img-right-clicked"
                : ""
            }`}
            src="/markHoofd.png"
            onClick={() => {
              const newData = { ...answersData }
              newData[orderedData[progress]?.id] = 1
              setAnswersData(newData)
            }}
          />
          <div className="speach-bulb-pointer"></div>
          <div className="speach-bulb">
            <p className="speach-bulb-text">
              {orderedData[progress]?.quote || ""}
            </p>
          </div>
          <div
            className={`buttongroup ${
              answersData[orderedData[progress]?.id] !== undefined
                ? "buttongroup-answer-given"
                : ""
            }`}
          >
            <button className={`button`} onClick={() => nextButtonClick()}>
              {progress >= orderedData.length - 1 ? "Klaar!" : "Volgende"}
            </button>
          </div>
        </div>
      </Game>
      <Leader
        leaderTitleStrings={["Wie", "Zegt", "Wat", "?"]}
        leaderContentNodes={[
          <img alt="person1" src="/djamillaHoofd.png" width="150" />,
          <img alt="person2" src="/markHoofd.png" width="150" />,
        ]}
      ></Leader>
    </Page>
  )
}

export default WieZegtWat
