import React, {
  CSSProperties,
  PropsWithChildren,
  useEffect,
  useState,
} from "react"
import "./result.css"
import { useNavigate } from "react-router-dom"

interface ResultProps {
  score?: number
  total?: number
  complete?: boolean
}
type resultState = "counting" | "countingComplete"

function Result({
  score,
  total,
  complete = false,
}: PropsWithChildren<ResultProps>): ReturnType<React.FC> {
  const [state, setState] = useState<resultState>("counting")
  const navigate = useNavigate()
  useEffect(() => {
    if (state === "counting") {
      const timeId = setTimeout(() => {
        setState("countingComplete")
      }, 4000)

      return () => {
        clearTimeout(timeId)
      }
    }
  })

  let comment = ""
  let gifLink = ""
  if (complete) {
    comment =
      "Uitstekend! Je bent een echte kenner. Mark & Djamilla zijn trots op jou!"
    gifLink = "/result-perfect.jpeg"
  } else if (score !== undefined && total !== undefined) {
    if (score === 0) {
      comment =
        "Helemaal niks goed. Het lijkt wel alsof je dit bewust hebt gedaan."
      gifLink = "/result-nul.gif"
    } else if (score / total > 1) {
      comment = "Hacker!"
      gifLink = "/result-hacker.gif"
    } else if (score / total === 1) {
      comment =
        "Perfect! Je bent een echte kenner. Mark & Djamilla zijn trots op jou!"
      gifLink = "/result-perfect.jpeg"
    } else if (score / total > 0.8) {
      comment = "Wat Goed! Een mooie uitslag."
      gifLink = "/result-goed.gif"
    } else if (score / total > 0.6) {
      comment = "Voldoende! Een mooie uitslag"
      gifLink = "/result-voldoende.gif"
    } else if (score / total > 0.55) {
      comment = "Oefh... hakken over de sloot. Net aan voldoende..."
      gifLink = "/result-net-voldoende.gif"
    } else if (score / total > 0) {
      comment = "Helaas, ONVOLDOENDE."
      gifLink = "/result-onvoldoende.gif"
    }
  }
  return (
    <div
      className="result"
      style={
        complete
          ? ({
              "--data-score": 10,
              "--data-total": 10,
            } as CSSProperties)
          : ({
              "--data-score": score,
              "--data-total": total,
            } as CSSProperties)
      }
    >
      {["counting", "countingComplete"].includes(state) &&
        (complete ? (
          <div className="result-complete">Helemaal compleet!</div>
        ) : (
          <div className="result-score">
            <p className="result-title">Jouw score</p>
            <span className="result-score-value"> / </span>
          </div>
        ))}
      <div
        className={`result-comment ${
          ["countingComplete"].includes(state) ? "result-animation-show" : ""
        }`}
      >
        <p className="result-comment-paragraph">{comment}</p>
        <img className="result-comment-img" src={gifLink} alt="Wat Goed" />
        <div className="result-comment-buttongroup">
          <button className={`button`} onClick={() => navigate("/")}>
            Terug
          </button>
          {score !== undefined &&
            total !== undefined &&
            !(score / total >= 1) && (
              <button className={`button`} onClick={() => navigate(0)}>
                Probeer opnieuw
              </button>
            )}
        </div>
      </div>
    </div>
  )
}

export default Result
