import React from "react"
import "./Navigation.css"
import { useNavigate, useLocation } from "react-router-dom"

function Navigation(): ReturnType<React.FC> {
  const navigate = useNavigate()
  const location = useLocation()
  return (
    <nav className="navigation">
      <button
        onClick={() => navigate("/home")}
        className={`${
          ["/home", "/"].includes(location.pathname) ? "button-active" : ""
        }`}
      >
        HOME
      </button>
      <button
        onClick={() => navigate("/quiz")}
        className={`${location.pathname === "/quiz" ? "button-active" : ""}`}
      >
        QUIZ
      </button>
      <button
        onClick={() => navigate("/kwp")}
        className={`${location.pathname === "/kwp" ? "button-active" : ""}`}
      >
        KWP
      </button>
      <button
        onClick={() => navigate("/wzw")}
        className={`${location.pathname === "/wzw" ? "button-active" : ""}`}
      >
        WZW
      </button>
    </nav>
  )
}

export default Navigation
